export const strings = {
    g_tag_id: 'G-F6R61L6WWG',
    backend_url: 'https://escotilhanews.com.br/api',
    backend_url_image: 'https://escotilhanews.com.br/api/articles/thumb'
}

export const ads = [
    {
        rectangle: 'logo_rectangle_josilene.webp',
        square: 'logo_squad_josilene.webp',
        url: ''
    },
    {
        rectangle: 'logo_rectangle_arena_naltica.webp',
        square: 'logo_squad_arena_naltica.webp',
        url: ''
    },
    {
        rectangle: 'logo_rectangle_cheia_de_charme.webp',
        square: 'logo_squad_cheia_de_charme.webp',
        url: ''
    },
    {
        rectangle: 'logo_rectangle_fundicao_maria_fernanda.webp',
        square: 'logo_squad_fundicao_maria_fernanda.webp',
        url: ''
    },
    {
        rectangle: 'logo_rectangle_posto_rm.webp',
        square: 'logo_squad_posto_rm.webp',
        url: ''
    },
]

export const editorials = [
    {
        key: 'noticias',
        label: 'ESCOTILHA NEWS',
        responsable: 'Capitão Horizonte',
        type: 'editorial',
        url: 'noticias',
        colunista: undefined
    },
    {
        key: 'pablorolimfala',
        label: 'OPINIÃO',
        responsable: 'Por Pablo Rolim',
        type: 'coluna',
        url: 'colunas/pablo-rolim-fala',
        colunista: {
            image: '/pablo.png',
            name: 'Pablo Rolim',
            description: 'Escrito por Pablo Rolim ex-vice prefeito da cidade de Várzea Alegre, estudou finanças e políticas publicas na University of Chicago, Gestão e finanças na Fundação Getúlio Vargas e Engenharia Civil na UFC',
            facelink: 'https://www.facebook.com/pablo.rolim.3',
            instalink: 'https://www.instagram.com/pablo.o.rolim'
        }
    },
    {
        key: 'politicanalata',
        label: 'POLÍTICA NA LATA',
        responsable: 'Por Capitão Horizonte',
        type: 'coluna',
        url: 'colunas/politica-na-lata',
        colunista: {
            image: '/capitaohorizonte.png',
            name: 'Capitão Horizonte',
            description: 'Escrito por Capitão Horizonte, É formado em Ciências Contábeis e pós graduado em Segurança Pública. ingressou na Marinha em 1990, após aprovação no concurso para Aprendiz de Marinheiro. Em 2011, já como Segundo-Sargento prestou concurso para Oficial passando em primeiro lugar. Serviu no Porta-Aviões Minas Gerais e no Porta-Aviões São Paulo. Assumiu funções importantes na Marinha, dentre elas Oficial de Inteligência, Encarregado do Armamento, Chefe do Departamento de Administração e Chefe do Departamento de Segurança do Tráfego Aquaviário da CPCE. Casado, tem 3 filhos e uma neta',
            facelink: 'https://www.facebook.com/capitaohorizonte',
            instalink: 'https://www.instagram.com/capitao_horizonte'
        }
    },
    {
        key: 'avidanosexigecoragem',
        label: 'A VIDA NOS EXIGE CORAGEM',
        responsable: 'Por Professor Hamillton Vale',
        type: 'coluna',
        url: 'colunas/a-vida-nos-exige-coragem',
        colunista: {
            image: '/hamilltonvale.png',
            name: 'Professor Hamillton Vale',
            description: 'Escrito por Hamillton Vale, Professor universitário, com graduação em Filosofia, Teologia, Gestão de Recursos Humanos, Gestão Pública e Administração de Empresas especialista em Filosofia Social e Política e Gestão Pública Municipal, mestre em Planejamento e Políticas Públicas, Doutor em Gestão e Pós Doutorado em Direitos Humanos e Direitos Difusos – Universidade de Salamanca, Espanha',
            facelink: 'https://www.facebook.com/hamilton.vale.1',
            instalink: 'https://www.instagram.com/hamillton20vale'
        }
    },
    {
        key: 'tecnologia',
        label: 'ESCOTILHA TECH',
        responsable: 'Matheus Lima',
        type: 'editorial',
        url: 'tecnologia',
        colunista: undefined
    },
    {
        key: 'vocesabia',
        label: 'VOCÊ SABIA?',
        responsable: 'Redação Escotilha',
        type: 'editorial',
        url: 'vocesabia',
        colunista: undefined
    },
    {
        key: 'portaldaconsciencia',
        label: 'PORTAL DA CONSCIÊNCIA',
        responsable: 'Por Paulo Costa',
        type: 'coluna',
        url: 'colunas/portal-da-consciencia',
        colunista: {
            image: '/paulocosta.png',
            name: 'Paulo Costa',
            description: 'Escrito por Paulo Costa, Formado em Psicologia pela Universidade Dr. Leão Sampaio, na cidade de Juazeiro do Norte no estado do Ceará, Brasil, Paulo Costa segue uma abordagem Fenomenológica humanista com ênfase em saúde e atuante em clínica desde 2013 até os dias atuais. Já trabalhou com grupos de arteterapia infantil, gestantes, álcool e drogas e clínica terapêutica atendendo o público como crianças, adolescentes, adultos e idosos. Já esteve professor universitário e de curso técnico de enfermagem na qual se encontrou muito com como professor para com essas turmas que guarda até os dias atuais em meu coração, respeitando e os defendendo. Já esteve atuando também como gerente das UBS - Unidades básica de saúde. Minha formação é em Gestão Hospitalar, Psicologia Hospitalar, Técnico em Perícia criminal, Hipnose Clínica, Arteterapia Infantil e especialista em Psicologia Hospitalar, Psicologia Forense e Perícia Criminal, Avaliações Psicológicas, Psiquiatria e Saúde Mental e Mestre em Psicanálise. O Psicólogo Paulo Costa, recebeu o prêmio de 1° lugar em destaque nos anos de 2017, 2018, 2019 e 2022 por possuir uma vasta experiência profissional dentre elas a clínica terapêutica',
            facelink: '',
            instalink: ''
        }
    },
]

export const editorialsObj = {
    noticias: editorials[0],
    pablorolimfala: editorials[1],
    politicanalata: editorials[2],
    avidanosexigecoragem: editorials[3],
    tecnologia: editorials[4],
    vocesabia: editorials[5],
    portaldaconsciencia: editorials[6]
}


export const regions = [
    {
        key: 'VA',
        label: 'VÁRZEA ALEGRE'
    },
    {
        key: 'CA',
        label: 'CARIRI'
    },
    {
        key: 'CS',
        label: 'CENTRO SUL'
    },
    {
        key: 'CE',
        label: 'CEARÁ'
    },
    {
        key: 'BR',
        label: 'BRASIL'
    },
    {
        key: 'MN',
        label: 'MUNDO'
    },
]